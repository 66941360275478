import React, { useContext, useState, useEffect, useRef } from 'react';
import { useScrollPosition } from '../hooks/useScrollPosition';
import useResizeObserver from '../hooks/useResizeObserver';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from './home/migration';
import { mainBody, about } from '../editable-stuff/config.js';
import { ThemeContext } from '../App';

const Navigation = React.forwardRef((props, ref) => {
  const { theme } = useContext(ThemeContext);
  const [isTop, setIsTop] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const navbarMenuRef = useRef();
  const navbarDimensions = useResizeObserver(navbarMenuRef);
  const navBottom = navbarDimensions?.bottom || 0;

  useScrollPosition(
    ({ currPos }) => {
      if (ref?.current && navbarDimensions) {
        const isAtTop = currPos.y + ref.current.offsetTop - navBottom > 5;
        setIsTop(isAtTop);
      }
    },
    [navBottom]
  );

  useEffect(() => {
    if (ref?.current && navbarDimensions) {
      setIsTop(window.scrollY === 0 || navBottom - window.scrollY >= ref.current.offsetTop);
    }
  }, [navBottom, navbarDimensions, ref]);

  const handleNavClick = () => setExpanded(false);

  return (
    <Navbar
      expanded={expanded}
      ref={navbarMenuRef}
      className={`px-3 fixed-top ${isTop ? 'navbar-transparent' : 'navbar-white'}`}
      expand="lg"
      variant={theme}
    >
      <Navbar.Brand className="navbar-brand" href={process.env.PUBLIC_URL + '/#home'}>
        {`<${mainBody.firstName} />`}
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className="toggler"
        onClick={() => setExpanded((prev) => !prev)}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-nav mr-auto" onClick={handleNavClick}>
          {[
            { label: 'About', link: '#aboutme' },
            { label: 'Skills', link: '#skills' },
            { label: 'Certifications', link: '#certifications' },
            { label: 'Blogs', link: '#blogs' },
            { label: 'Proficiency', link: '#proficiency' },
            { label: 'Get-In-Touch', link: '#getintouch' },
            { label: 'Resume', link: about.resume, isExternal: true }
          ].map((item, index) => (
            <NavLink
              key={index}
              className="nav-item lead"
              href={item.isExternal ? item.link : `${process.env.PUBLIC_URL}/${item.link}`}
              target={item.isExternal ? '_blank' : undefined}
              rel={item.isExternal ? 'noreferrer noopener' : undefined}
            >
              {item.label}
            </NavLink>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
});

export default Navigation;
